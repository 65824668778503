<template>
    <div id="faceDeviceBgImageDetail" ref="faceDeviceBgImageDetail" v-loading="loading" :element-loading-text="$t('alarm.a0')">
         <div class="header">
        <!-- 左上角头部开关    -->
            <div class="header-title">
                 <!-- <a-icon type="desktop"  class="header-icon"/> -->
                <img :src="getImages" />
                <span>{{device.faceDeviceName}}</span>  
            </div>
            <div>
                <!-- 返回上一级箭头   -->
                <a-icon
                    type="close" 
                    :style="{ fontSize: '20px', color: '#7682CE' }"
                   @click="goBack"
                />
            </div>
        </div>
        <div class="bg-main" >
                <div v-loading="sloading" :element-loading-text="$t('alarm.a0')">
                    <div class="bg-title" >
                        <span> {{$t('distribution.a52')}} </span>
                        <!--  人脸终端信息 -->
                        <a-button v-if="devices.length===1"  type="primary" @click="saveDevice" > {{$t('energy.ok')}} </a-button>
                    </div>
                    <div v-if="devices.length>1" class="bg-device" >
                        <a-tag v-for="(item,index) in devices" :key="index">{{item.faceDeviceName?item.faceDeviceName:''}}</a-tag>
                    </div>
                    <div v-else class="bg-device2" >
                        <a-descriptions title="">
                            <a-descriptions-item :label="$t('facialInfo.a3')"><a-input v-model="device.faceDeviceName" allow-clear /></a-descriptions-item>
                            <a-descriptions-item :label="$t('facialInfo.a10')"><a-input v-model="device.faceDeviceNumber" allow-clear /></a-descriptions-item>
                            
                            <a-descriptions-item :label="$t('facialInfo.a12')">
                                <!-- {{ getBrandName(device.deviceBrand,device) }} -->
                                <a-select v-model="device.deviceBrand" style="width: 200px;">
                                    <a-select-option v-for="(brand,idx) in brands" :value="brand.no" :key="idx" >
                                        {{ brand.name }}
                                    </a-select-option>
                                    
                                </a-select>
                            </a-descriptions-item>
                            <a-descriptions-item :label="$t('facialInfo.a13')"><a-input v-model="device.deviceIp" allow-clear /></a-descriptions-item>
                            <a-descriptions-item :label="$t('distribution.a53')"><a-input v-model="device.imgWay" allow-clear /></a-descriptions-item>

                            <a-descriptions-item :label="$t('facialInfo.a14')"><a-input-password v-model="device.devicePassWord" allow-clear /></a-descriptions-item>
                            <a-descriptions-item :label="$t('facialInfo.a15')"><a-input v-model="device.deviceHeartUrl" allow-clear /></a-descriptions-item>
                            <a-descriptions-item :label="$t('facialInfo.a16')"><a-input v-model="device.deviceIdentifyUrl" allow-clear /></a-descriptions-item>
                            <a-descriptions-item :label="$t('facialInfo.a17')"><a-input v-model="device.middlewareUrl" allow-clear /></a-descriptions-item>
                            <a-descriptions-item :label="$t('facialInfo.a11')">{{ device.readerName }}</a-descriptions-item>
                            <a-descriptions-item :label="$t('facialInfo.a18')"><a-input v-model="device.faceDevice" allow-clear /></a-descriptions-item>
                            
                        </a-descriptions>
                    </div>
                </div>
                <div v-if="device.deviceBrand===4" v-loading="uloading" :element-loading-text="$t('alarm.a0')">
                    <div class="bg-title" >
                        <span>
                            <!-- 背景图片 -->
                            {{$t('distribution.a54')}}
                        </span>
                        <a-button  type="primary" @click="deleteAllBgImage" > 
                            <!-- 全部删除  -->
                            {{$t('distribution.a55')}}
                        </a-button>
                    </div>
                    <div class="bg-box" v-for="(item,index) in images" :key="index">
                        <div class="bg-label">
                        {{item.index}}
                        </div>
                        <img v-if="item.base64" class="bg-image" :src="item.base64" alt="avatar" @error="(e)=>{imgOnError(e,item,1)}" />
                        <a-icon v-else class="bg-icon" type="file-image" />
                        <div  class="bo-tool">
                            <a-button  type="primary" @click="deleteFaceBgImage(item)" > 
                                <!-- 删除  -->
                                {{$t('distribution.a56')}}
                            </a-button>
                            <a-upload :show-upload-list="false" 
                            @change="(file)=>{ return handleChange(file,item);}"
                            :before-upload="beforeUpload" > 
                                <a-button> <a-icon type="upload" /> 
                                    <!-- 选择图片  -->
                                    {{$t('distribution.a57')}}
                                </a-button>
                            </a-upload>
                        </div>
                    </div> 
                    <div class="bg-tool" style="clear: both;">
                        <a-button  type="primary" @click="uploadBgImage" > 
                            <!-- 上传图片  -->
                            {{$t('distribution.a58')}}
                        </a-button>
                    </div>
                </div>
                <div v-if="device.deviceBrand===4" v-loading="rloading" :element-loading-text="$t('alarm.a0')">
                    <div  class="bg-title" >
                        <span>
                            <!-- 设备现场图 -->
                            {{$t('distribution.a59')}}
                        </span>
                    </div>
                    <div class="bg-box" v-for="(item,index) in images" :key="index">
                        <div class="bg-label">
                            {{item.index}}
                        </div>
                        <img v-if="item.oimage && item.oimage!==''" class="bg-image" :src="item.oimage" alt="avatar" @error="(e)=>{imgOnError(e,item,2)}" />
                        <a-icon v-else class="bg-icon" type="file-image" />
                        <div class="bg-tool">
                            <a-button  type="primary" @click="readDeviceBgImage2(item.index)" > 
                                <!-- 读取图片  -->
                                {{$t('distribution.a60')}}
                            </a-button>
                        </div>
                    </div>
                    <div class="bg-tool" style="clear: both;">
                            <a-button  type="primary" @click="readDeviceBgImage" > 
                                <!-- 读取全部图片  -->
                                {{$t('distribution.a61')}}
                            </a-button>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
import { changeSaveBgImage,deleteBgImage,getFaceDeviceBgImage,getFaceDeviceBgImageByIndex,getFaceDeviceInfo} from "../../api/face";
import { setCompile } from "../../api/door";
import moment from "moment";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function getImgPx(file) {
    const image = new Image();
    image.src = file;
    return new Promise((resolve) => {
        image.onload = () =>{
            const width = image.width;
            const height = image.height;
            resolve({width,height});
        }
    });
}

const brands =[
    {no: 0, key: "0@@-@@1", name: "请选择"},
    {no: 1, key: "1@@-@@1", name: "宇泛"},
    {no: 2, key: "2@@-@@1", name: "奇智"},
    {no: 3, key: "3@@-@@1", name: "海康"},
    {no: 4, key: "4@@-@@1", name: "海康-HEOP"}
];

export default {
  name: 'faceDeviceBgImageDetail',
  data() {
    
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata260')));
        } else {
            callback();
        }
    };
    return {
        loading:false,
        sloading:false,
        uloading:false,
        rloading:false,
        visible:false,
        images:[         
        ],
        devices:[],
        device:{
            siteId: 0,
            deviceBrand: 0,
            readerId: 0,
            readerName: "",
            faceDeviceNO: "",
            faceDeviceName: "",
            faceDeviceNumber: "",
            faceDeviceStatus: "",
            deviceIp: "",
            devicePassWord: "",
            deviceHeartUrl: "",
            deviceIdentifyUrl: "",
            middlewareUrl: "",
            faceDevice: "",
            imgWay: null,
            imgType: null
        },
        brands,
        brand:0

    };
  },
  mounted(){
        this.readFaceDeviceInfo();
        this.images = [];
        this.devices = [];
        for(let n=1;n<6;n++){
            this.images.push({index:n,msg:'',image:'',oimage:''});
        }
  },
  computed:{
    getImages: function () {
      if(this.$route.query.iconNo){
          let iconNo = this.$route.query.iconNo;
          return require("../../../public/images/facility/" + iconNo + ".png");
      }
      return require("../../../public/images/facility/0.png");
    },
  },
  methods: {
      
    goBack() {
        this.$router.go(-1);
    },
    readFaceDeviceInfo(){
        const data = {
          siteId: this.$route.query.siteId,
          id: this.$route.query.deviceId
        };
        this.loading = true;
        getFaceDeviceInfo(data)
        .then((res) => {
            this.loading = false;
            if (res.errorCode == "00") {
                // this.$message.success(res.msg);
               
                this.device = res.data;
                this.devices.push(this.device);
                this.brand = this.device.deviceBrand;
                if(this.brand===4){
                    this.readDeviceBgImage();
                }

            } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
            }
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    imgOnError(e,item,tag){
        console.log(tag);
        if(tag===2 && item.oimage !=''){
            this.images[item.index-1].oimage = '';
        } else if(tag===1 && item.base64 !='') {
            this.images[item.index-1].image = '';
            this.images[item.index-1].base64 = '';
        }
    },
    async handleChange(e,item){
        const {file} = e;
        this.uloading = true;
        item.change = true;
        if(file.originFileObj){
            item.base64 = await getBase64(file.originFileObj);
        }else{
            item.base64 = await getBase64(file);
        }
        // console.log(await getImgPx(item.base64));
        this.uloading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      
      if (!isJpgOrPng) {
        this.$message.error(this.$t('distribution.a62'));//'只能上传png和jpg格式的图片!'
        return false;
      }
      const isLt2M = file.size / 1024  < 300;
      if (!isLt2M) {
            this.$message.error(this.$t('distribution.a63'));//'图片大小不能超过300KB!');
            return false;
      }
    //   const base64 = (await getBase64(file));
    //   const img = (await getImgPx(base64));
    //   const pxrate = img.width/img.height;
    //   const ispx = (pxrate>=0.55 && pxrate<=0.65)
    //   if(!ispx){
    //        this.$message.error('图片分辨率请尽量控制在600*1024左右');
    //        return false;
    //   }

    //   item.base64 = base64;
      return false;
    },
    // 编辑的确定按钮方法
    saveDevice() {
      
      let data = this.device;
      this.$confirm({
            title:  this.$t('distribution.a64'),//'确认要保存所设置的信息吗？',//
            centered: true,
            onOk: () => {
                this.sloading = true;
                setCompile(data)
                .then((res) => {
                    this.sloading = false;
                    if (res.errorCode == "00") {
                        this.$message.success(res.msg);
                    } else if (res.errorCode == "02") {
                        this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    this.sloading = false;
                    console.log(err);
                });
            },
        }); 
    },
    // 编辑的取消按钮方法
    handleCancel() {
      this.visible = false;
    },
    readDeviceBgImage3(){
        this.images.forEach(element => {
            if(element.change){
                this.readDeviceBgImage2(element.index);
            }
        });
        
    },
    readDeviceBgImage4(images){
        images.forEach(element => {
            if(element.change){
                this.readDeviceBgImage2(element.index);
            }
        });
    },
    readDeviceBgImage2(imageIndex){
        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.siteId,
          id: this.device.faceDeviceNO,
          imageIndex:imageIndex,
        };
        this.rloading = true;
        this.uloading = true;
        getFaceDeviceBgImageByIndex(data)
        .then((res) => {
            this.rloading = false;
            this.uloading = false;
            if (res.errorCode == "00") {
                // this.$message.success(res.msg);
                this.images[imageIndex-1] = {};
                this.images[imageIndex-1] = res.data.image;
                this.images[imageIndex-1].change = false;
            }
        })
        .catch((err) => {
            this.rloading = false;
            this.uloading = false;
            console.log(err);
        });
    },
    readDeviceBgImage(){
        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.siteId,
          id: this.device.faceDeviceNO
        };
        this.rloading = true;
        this.uloading = true;
        getFaceDeviceBgImage(data)
        .then((res) => {
            this.rloading = false;
            this.uloading = false;
            if (res.errorCode == "00") {
                // this.$message.success(res.msg);
                this.images = [];
                this.images = res.data.images;
                this.images.forEach(element => {
                    this.images.change = false;
                });
            }
        })
        .catch((err) => {
            this.rloading = false;
            this.uloading = false;
            console.log(err);
        });

        
    },
    uploadBgImage(){
        let idList = [];
        
        this.devices.forEach(element => {
            idList.push(element.faceDeviceNO);
        });

        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.siteId,
          idList: idList,
          images:this.images
        };
        
        console.log(data)
        this.$confirm({
            title:  this.$t('distribution.a65'),//'确认要上传所选择的图片吗？',//
            centered: true,
            onOk: () => {
                this.uloading = true;
                changeSaveBgImage(data)
                .then((res) => {
                    this.uloading = false;
                    const { errorCode, msg } = res;
                    if (errorCode == "00" || errorCode == "03") {
                        this.$message.success(msg);
                    } else {
                        this.$message.error(msg);
                    }
                    this.readDeviceBgImage4(data.images);

                })
                .catch((err) => {
                    this.uloading = false;
                    console.log(err);
                });
            },
        }); 
    },

    deleteFaceBgImage(item){
        let idList = [];
        idList.push(this.device.faceDeviceNO);
        let images = [];
        images.push(item);
        this.deleteFaceImage(idList,images, this.$t('distribution.a66'));//'确认要删除该图片吗？');
    },
    deleteAllBgImage(){
        let idList = [];
        this.devices.forEach(element => {
            idList.push(element.faceDeviceNO);
        });

        this.deleteFaceImage(idList,this.images, this.$t('distribution.a67'));//'确认要删除所有图片吗？');
    },
    deleteFaceImage(idList,images,msg){
        const data = {
          action: 1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.siteId,
          idList: idList,
          images:images,
        };
        
        console.log(data)
        this.$confirm({
            title:  msg,//'确认要上传所选择的图片吗？'
            centered: true,
            onOk: () => {
                this.uloading = true;
                deleteBgImage(data)
                .then((res) => {
                    this.uloading = false;
                    const { errorCode, msg } = res;
                    if (errorCode == "00" || errorCode == "05") {
                        this.$message.success(msg);
                        images.forEach(element => {
                            this.images[element.index-1].base64 = '';
                            this.images[element.index-1].image = '';
                            this.images[element.index-1].oimage = '';
                        });
                    } else {
                        this.$message.error(msg);
                    }
                    
                    
                })
                .catch((err) => {
                    this.uloading = false;
                    console.log(err);
                });
            },
        }); 
    },
    getBrandName(no,item){
      let name = '';
      brands.forEach(element => {
        if(no+''===element.no+''){
          name = element.name;
        }
      });

      return name;
    },
  },
};
</script>
<style scoped>

#faceDeviceBgImageDetail {
    height: 100%;
    width:100%;
    position: relative;  
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #bdbcbc;
    background: #ffffff;
    overflow: hidden;
    padding:10px;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 10px 20px;
  border-bottom: 1px solid #cccdcd;
  background: #ffffff;
  white-space: nowrap;
  overflow: hidden;
}
.bg-device{
    width:100%;
    height: 100px;
    overflow: auto;
    padding: 8px;
    border:1px solid #e8e8e8;
    margin-bottom: 5px;
}
.bg-device2{
    width:100%;
    height: 220px;
    overflow: auto;
    padding: 8px;
    border:1px solid #e8e8e8;
    margin-bottom: 5px;
}
.bg-main{
    width:100%;
    height: calc(100% - 80px);
    overflow: auto;
}
.bg-main::after{
    clear: both;
}
.bg-title{
    padding: 8px 5px ;
    background-color: #e8e8e8;
    font-size:16px ;
    display: flex;
    justify-content: space-between;
}
.bg-box{
    width: 200px;
    height: 360px;
    float: left;
    margin: 5px;
    
    border: 1px solid #e8e8e8;
    position: relative;  
    text-align: center;
}
.bg-image{
    padding: 5px;
    width : 100%;
    height: 320px;
}
.bg-icon{
    margin-top:50px;
    padding: 5px;
    width : 100%;
    height: 270px;
    font-size:160px ;
}
.bg-label{
    width: 188px;
    text-align: center;
    height: 30px;
    color: #fff;
    padding: 5px;
    margin: 5px;
    background-color: #000000;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
}
.bo-tool{
    display: flex;
    justify-content: space-between;
    padding:0 5px;
}
.bg-tool{
    text-align: right;
    padding:5px;
}
div /deep/ .ant-modal-body {
    padding:10px;
}
.red{
    color: #ff0000;
}
</style>