import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'


export function getFaceDeviceInfo(params) {
    console.log('getFaceDeviceInfo',params);
    return request({
        url: '/bivale/entrance/face/info/'+params.id+'?siteId='+params.siteId,
        method: 'post',
        data:null
    });
}
// 上传更新终端背景图片
export function changeSaveBgImage(params) {
    return request({
        url: '/bivale/face/image/upload',
        method: 'post',
        data:params
    });
}

// 删除终端背景图片
export function deleteBgImage(params) {
    return request({
        url: '/bivale/face/image/delete',
        method: 'post',
        data:params
    });
}

export function getFaceDeviceBgImage(params) {
    return request({
        url: '/bivale/face/image/list',
        method: 'post',
        data:params
    });
}

export function getFaceDeviceBgImageByIndex(params) {
    return request({
        url: '/bivale/face/image/data',
        method: 'post',
        data:params
    });
}