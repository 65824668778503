import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'

// 获取门禁模块卡片统计数据
export function getDoorCardData(params) {
    return request({
        url: 'bivale/entrance/equipment/statisticsArray',
        method: 'get',
        params
    });
}

// 门禁模块--设备页
// 获取楼层/租户选择列表  传入id为站点Id(siteId)
export function getTenantList(id) {
    return request({
        url: `bivale/custom/tenant/list/${id}`,
        method: 'get',
    });
}

// 获取设备页卡片对象列表 
export function getCardList(params) {
    console.log("GetCardList params",params);
    return request({
        url: "bivale/entrance/equipment/listDetail",
        method: 'get',
        params,
    });
}

// 获取设备页卡片对象列表 
export function getDoorList(params) {
    console.log("getDoorList params",params);
    return request({
        url: "bivale/entrance/equipment/door/list",
        method: 'get',
        params,
    });
}

// 门禁卡片开关远程操作(注：设备开关/解锁/复原)
export function doorSwitch(params) {
    return request({
        url: "bivale/entrance/equipment/telminal/excute",
        method: 'post',
        data: params,
    });
}

// 门禁模块--警报页
// 获取查询数据
export function alarmData(params) {
    return request({
        url: 'bivale/alarm/history/entrance/listCustom',
        method: 'get',
        params
    });
}
// 报警页--添加注释 
export function addComment(query) {
    return request({
        url: "bivale/alarm/history/comment/update",
        method: 'post',
        params: query,
    });
}

// 报警页--报警确认 
export function acknowledgement(query) {
    return request({
        url: "bivale/alarm/history/status/acknowledgement/update",
        method: 'post',
        params: query,
    });
}

// 报警页--手动恢复
export function manualRecovery(query) {
    return request({
        url: "bivale/alarm/history/restore/operation",
        method: 'post',
        params: query,
    });
}

// 报警页--应对（处理）
export function processing(query) {
    return request({
        url: "bivale/alarm/history/status/correspondence/update",
        method: 'post',
        params: query,
    });
}

// 报警页--单条记录的操作（报警确认，处理，添加注释） added by li_yj on 2023-3-28
export function singleOperation(body) {
    return request({
        url: "bivale/alarm/history/single/operation",
        method: 'post',
        data: body,
    });
}

// 门禁模块--记录
// 通行记录（卡片记录）页获取查询数据
export function throughData(params) {
    return request({
        url: 'bivale/history/entrance/listCustom',
        method: 'get',
        params
    });
}

// 通行记录（卡片记录）--添加注释
export function cardAnnotation(query) {
    return request({
        url: "bivale/history/comment/update",
        method: 'post',
        params: query,
    });
}

// 考勤记录页获取查询数据
export function attendanceData(params) {
    return request({
        url: 'bivale/history/entrance/listAttendance',
        method: 'get',
        params
    });
}

// 操作·控制记录页获取查询数据
export function controlRecordData(params) {
    return request({
        url: 'bivale/history/operate/listCustom',
        method: 'get',
        params
    });
}

// 门禁模块--设置
// 门禁模块--门禁时间表页--列表数据获取
export function doorTimetableListKT(params) {
    return request({
        url: 'bivale/history/entrance/schedule/listKT',
        method: 'get',
        params
    });
}

// 门禁模块--门禁时间表页--获取详细信息
export function getDID(params) {
    return request({
        url: 'bivale/history/entrance/schedule/infoDetail',
        method: 'get',
        params
    });
}

// 门禁模块--执行时间表页--获取详细信息
export function getRunDID(params) {
    return request({
        url: 'bivale/history/entrance/runSchedule/infoDetail',
        method: 'get',
        params
    });
}

// 门禁模块--门禁时间表页--时间表变更api
export function doorTtUpdate(body, query) {
    return request({
        url: "bivale/history/entrance/schedule/update",
        method: 'post',
        data: body,
        params: query,
    });
}

// 门禁模块--门禁时间表页--时间表日历变更api
export function scheduleCalendarUpdate(query) {
    return request({
        url: "bivale/history/entrance/scheduleCalendar/update",
        method: 'post',
        params: query,
    });
}

// 门禁模块--门禁时间表页--时间表变更name的api
export function doorTtUpdateName(params) {
    return request({
        url: "bivale/history/entrance/scheduleName/update",
        method: 'post',
        params,
    });
}

// 门禁模块--门禁时间表页--复制全部时间表api
export function copyAllTt(params) {
    return request({
        url: "bivale/history/entrance/schedule/copy",
        method: 'post',
        params,
    });
}

// 门禁模块--门禁时间表页--时间表内单条时间复制api
export function copyOneTt(params) {
    return request({
        url: "bivale/history/entrance/schedule/copyDay",
        method: 'post',
        params,
    });
}

// 车场设置页获取查询数据
export function parkingData(params) {
    return request({
        url: 'bivale/authentication/carPark/configuration',
        method: 'get',
        params
    });
}

// 车场设置页--人员操作
export function personnelPractice(params) {
    return request({
        url: 'bivale/authentication/carPark/configuration/setUpGroupInfo',
        method: 'post',
        params
    });
}

// 门禁模块--车牌管理--查询接口
export function plateNumberDate(body, query) {
    return request({
        url: "bivale/authentication/search/personCarList",
        method: 'post',
        data: body,
        params: query,
    });
}

// 门禁模块--车牌管理--车牌列表删除
export function plateNumberDelete(body) {
    return request({
        url: "bivale/authentication/carPark/personCarInfo/delete",
        method: 'delete',
        data: body,
    });
}

// 门禁模块--车牌管理--人员车牌管理信息获取
export function personPlateNumberData(params) {
    return request({
        url: 'bivale/authentication/carPark/personCarInfo',
        method: 'get',
        params
    });
}

// 门禁模块--车牌管理--人员车牌管理信息设置
export function plateNumberSetUp(body) {
    return request({
        url: "bivale/authentication/carPark/personCarInfo/setUp",
        method: 'post',
        data: body,
    });
}

// 门禁模块--车牌管理--车牌管理和添加车牌的设置
export function plateNumberSet(body, query) {
    return request({
        url: "bivale/authentication/carPark/personCarInfo/set",
        method: 'post',
        data: body,
        params: query,
    });
}

// 门禁模块--车牌管理--车牌管理和添加车牌的设置
export function getPlateNumberParams(siteId) {
    return request({
        url: "bivale/authentication/carPark/person/car/params/"+siteId,
        method: 'get'
    });
}

// 门禁模块--通行模式--列表数据获取 
export function getTPList(params) {
    return request({
        url: 'bivale/accessRole/listKT',
        method: 'get',
        params
    });
}

// 门禁模块--通行模式--列表数据获取 
export function getAccessRoleList(siteId) {
    return request({
        url: 'bivale/accessRole/'+siteId+'/list',
        method: 'get',
    });
}


// 门禁模块--通行模式--获取右侧页面详细数据
export function getTPData(params) {
    return request({
        url: 'bivale/accessRole/crListAccessRole',
        method: 'get',
        params
    });
}

// 门禁模块--通行模式--通行模式复制
export function passCopy(params) {
    return request({
        url: "bivale/accessRole/entrance/pass/copy",
        method: 'post',
        params,
    });
}

// 门禁模块--认证终端编辑--获取全部的认证终端
export function getAllTerminal(params) {
    return request({
        url: 'bivale/accessRole/crList',
        method: 'get',
        params
    });
}

// 门禁模块--认证终端编辑--更新已注册终端列表 
export function UpdateregisterList(body) {
    return request({
        url: "bivale/accessRole/entrance/pass/update",
        method: 'post',
        data: body,
    });
}

// 门禁模块--获取日历列表
export function getCalendar(params) {
    return request({
        url: 'bivale/calender/siteList',
        method: 'get',
        params
    });
}

// 门禁模块--通行模式--通行模式名称修改 
export function accessRoleName(params) {
    return request({
        url: "bivale/accessRole/updateName",
        method: 'post',
        data: params,
    });
}



// 门禁模块--读卡器报警--列表数据获取
export function getCRList(params) {
    return request({
        url: 'bivale/alarm/history/entrance/crAlerm',
        method: 'get',
        params
    });
}

// 门禁模块--读卡器报警--设置信息数据获取 
export function getSetInfo(params) {
    return request({
        url: 'bivale/alarm/history/entrance/crAlermSetting',
        method: 'get',
        params
    });
}

// 门禁模块--读卡器报警--设置信息数据获取
export function setWarningSettings(body) {
    return request({
        url: "bivale/alarm/history/entrance/crAlerm/setting",
        method: 'post',
        data: body,
    });
}

// 门禁模块--人脸认证信息页--数据获取
export function getFacialInfo(body, query) {
    return request({
        url: 'bivale/entrance/face/list',
        method: 'post',
        data: body,
        params: query
    });
}

// 门禁模块--人脸认证信息页--编辑按钮修改信息的接口
export function setCompile(params) {
    return request({
        url: 'bivale/entrance/face/update',
        method: 'post',
        data: params
    });
}

// 门禁模块--火灾输出控制组页
// 门禁模块--火灾输出控制组页-获取侧边栏数据 
export function getNavigation(params) {
    return request({
        url: 'bivale/history/entrance/firectrl/listKT',
        method: 'get',
        params
    });
}

// 门禁模块--火灾输出控制组页-获取页面详细数据
export function getFireControlGroup(params) {
    return request({
        url: 'bivale/history/entrance/firectrl/detail',
        method: 'get',
        params
    });
}

// 门禁模块--状况表示
// 门禁模块--状况表示--滞留人员页--滞留人员显示信息查询
export function overStayPersonInfo(params) {
    return request({
        url: 'bivale/history/entrance/schedule/overStayPersonInfo',
        method: 'get',
        params
    });
}

// 门禁模块--状况表示--滞留人员页--删除滞留人员
export function retentionDeletea(body) {
    return request({
        url: 'bivale/history/entrance/OverStayAreaValue/delete',
        method: 'post',
        data: body
    });
}

// 门禁模块--状况表示--滞留人员页--超时设置
export function overtimeSet(body) {
    return request({
        url: 'bivale/history/entrance/OverRunTime/update',
        method: 'post',
        data: body
    });
}

// 门禁模块--状况表示--室内人员页--室内人员显示信息查询 
export function stayPersonInfo(params) {
    return request({
        url: 'bivale/history/entrance/schedule/stayPersonInfo',
        method: 'get',
        params
    });
}

// 门禁模块--状况表示--室内人员页--重置
export function PersonReset(body) {
    return request({
        url: 'bivale/history/entrance/StayAreaPerson/reset',
        method: 'post',
        data: body
    });
}

// 门禁模块--状况表示--室内人员页--室内人员设置--查询所有室内人员信息
export function allStayPersonInfo(params) {
    return request({
        url: 'bivale/history/entrance/schedule/allStayPersonInfo',
        method: 'get',
        params
    });
}

// 门禁模块--状况表示--室内人员页--室内人员设置--设置 
export function indoorPersonSet(body) {
    return request({
        url: 'bivale/history/entrance/StayAreaPerson/setting',
        method: 'post',
        data: body
    });
}

// 门禁模块--状况表示--滞留人员--超时时间查询 
export function overRunTime(params) {
    return request({
        url: 'bivale/history/entrance/schedule/overRunTime',
        method: 'get',
        params
    });
}

// 门禁模块--状况表示--滞留人员区域--查询信息 
export function areaDeviceInfo(params) {
    return request({
        url: 'bivale/history/entrance/schedule/areaDeviceInfo',
        method: 'get',
        params
    });
}

// 门禁模块--状况表示--室内人员区域和滞留人员区域--区域列表添加
export function areaListAdd(body) {
    return request({
        url: 'bivale/history/entrance/StayArea/add',
        method: 'post',
        data: body
    });
}

// 门禁模块--状况表示--室内人员区域和滞留人员区域--区域列表删除
export function areaListDelete(body) {
    return request({
        url: 'bivale/history/entrance/StayArea/delete',
        method: 'post',
        data: body
    });
}

// 门禁模块--状况表示--室内人员区域和滞留人员区域--变更名称 
export function areaNameUpdate(body) {
    return request({
        url: 'bivale/history/entrance/areaName/update',
        method: 'post',
        data: body
    });
}

// 门禁模块--状况表示--室内人员区域和滞留人员区域--查询所有人员区域设备信息
export function allAreaDeviceInfo(params) {
    return request({
        url: 'bivale/history/entrance/schedule/allAreaDeviceInfo',
        method: 'get',
        params
    });
}

// 门禁模块--状况表示--室内人员区域和滞留人员区域--区域编辑设置 
export function areaCompileSet(body) {
    return request({
        url: 'bivale/history/entrance/areaDeviceInfo/update',
        method: 'post',
        data: body
    });
}

// 门禁模块--实际数据页
// 门禁模块--实际数据页--数据输出
export function dataDownload(body, query) {
    return fileDownload({
        url: "bivale/outPutdData/download",
        method: 'post',
        responseType: "blob",
        data: body,
        params: query,
    });
}

// 门禁模块--实际数据页--门列表数据查询
export function dataDoorGet(body, query) {
    return request({
        url: "bivale/outPutdData/doorEquipmentList",
        method: 'post',
        data: body,
        params: query,
    });
}

// 门禁模块--实际数据页--卡列表数据查询 
export function getAllUseCardList(body, query) {
    return request({
        url: "bivale/authentication/search/getAllUseCardList",
        method: 'post',
        data: body,
        params: query,
    });
}

// 门禁模块--详情页
// 门禁模块--详情页--门详情页--获取门详情页数据 
export function getDoorDetails(params) {
    return request({
        url: "bivale/entrance/equipment/door/info",
        method: 'get',
        params,
    });
}

//add by li_yj on 2023-2-21
// 门禁模块--详情页--门详情页--获取门操作状态 
export function getDoorOperateStatus(params) {
    return request({
        url: "bivale/entrance/equipment/door/operateStatus",
        method: 'get',
        params,
    });
}

//add by li_yj on 2023-3-16
// 门禁模块--详情页--门详情页--获取门操作状态 
export function getDoorItemStatus(params) {
    return request({
        url: "bivale/entrance/equipment/door/itemStatus",
        method: 'get',
        params,
    });
}

// 门禁模块--详情页--基本信息名称修改
export function baseInfoUpdataName(params) {
    return request({
        url: "bivale/entrance/equipment/telminal/updateName",
        method: 'post',
        data: params,
    });
}

// 门禁模块--时间表恢复组件修改设定接口
export function setTimetableResumption(params) {
    return request({
        url: "bivale/entrance/equipment/telminal/recoverSchedule",
        method: 'post',
        data: params,
    });
}

// 门禁模块--手动时间表恢复组件修改设定接口
export function ManualRecoverySchedule(params) {
    return request({
        url: "bivale/entrance/equipment/telminal/recoverScheduleManually",
        method: 'post',
        data: params,
    });
}

// 门禁模块--详情页--读卡器详情页-获取读卡器详情页数据
export function getCardReaderDetails(params) {
    return request({
        url: "bivale/entrance/equipment/card/info",
        method: 'get',
        params,
    });
}

// 门禁模块--设置当次日时间表组件接口
export function updateScheduleTable(params) {
    return request({
        url: "bivale/entrance/equipment/telminal/UpdateScheduleTable",
        method: 'post',
        data: params,
    });
}

// 门禁模块--详情页--闸机详情页-获取闸机详情页数据
export function getGateMachineDetails(params) {
    return request({
        url: "bivale/entrance/equipment/flapper/info",
        method: 'get',
        params,
    });
}

// 门禁模块--详情页--道闸详情页-获取道闸详情页数据
export function getbarrierGateDetails(params) {
    return request({
        url: "bivale/entrance/equipment/barrierGate/info",
        method: 'get',
        params,
    });
}

// 门禁模块--门详情页--门禁设备详情信息组件修改操作接口
export function setDoorDeviceDetail(params) {
    return request({
        url: "bivale/entrance/equipment/telminal/updateDoorDetail",
        method: 'post',
        data: params,
    });
}

// 门禁模块--门详情页--读卡器设备详情信息组件修改操作接口
export function setReaderDetail(params) {
    return request({
        url: "bivale/entrance/equipment/telminal/updateReaderDetail",
        method: 'post',
        data: params,
    });
}

// 门禁模块--通行模式--通行模式名称修改 
export function updateOverRunTime2(params) {
    return request({
        url: "bivale/history/entrance/OverRunTime/update",
        method: 'post',
        data: params,
    });
}


// 门禁模块--卡片记录-获取人脸认证原始人脸数据
export function getFaceRecord(data) {
    //
    return request({
        url: "bivale/history/entrance/faceRecord?siteId="+data.siteId+"&params="+data.params,
        // url: "cc.json",
        method: 'get',
    });
}


// 车辆通行记录（车辆记录）页获取查询数据
export function getCarRecord(params) {
    return request({
        url: 'bivale/history/car/listCustom',
        method: 'get',
        params
    });
}
//车辆车牌识别图片信息
export function getCarImageRecord(data) {
    return request({
        url: "bivale/history/car/record?siteId="+data.siteId+"&params="+data.params,
        method: 'get',
    });
}
export function getPassagewayCardData(data) {
    let url = 'bivale/entrance/'+data.clientId+'/equipment/statistics/'+data.siteId+'/passageway';
    return request({
        url: url,
        method: 'get'
    });
}

//车场设备区域数据初始方法
export function carParkAreaInit(params) {
    return request({
        url: "bivale/entrance/park/area/init",
        method: 'post',
        data: params,
    });
}

//车场设备区域数据初始方法
export function remoceCarParkAreaData(params) {
    return request({
        url: "bivale/entrance/park/area/remove",
        method: 'post',
        data: params,
    });
}

//车场设备区域数据初始方法
export function getCarParkAreaData(params) {
    return request({
        url: "bivale/entrance/park/area/list",
        method: 'post',
        data: params,
    });
}

//车场设置---车场组织结构数据
export function getParkDeptData(params) {
    return request({
        url: "bivale/entrance/park/dept/list",
        method: 'post',
        data: params,
    });
}

//车场设置---人员组织结构数据
export function getPersonGrData(params) {
    return request({
        url: "bivale/entrance/park/persongr/list",
        method: 'post',
        data: params,
    });
}
//车牌管理---车牌数据
export function getPersonCarData(id) {
    return request({
        url: "bivale/authentication/carPark/car/info/"+id,
        method: 'get',
        data: null,
    });
}
//车牌管理---车牌列表数据
export function getPersonCarListData(params) {
    return request({
        url: "bivale/authentication/carPark/car/list",
        method: 'post',
        data: params,
    });
}
//设备转换模块--读取已转换设备
export function getConvertedEquipmentList(params) {
    return request({
        url: "bivale/entrance/equipment/convert/list",
        method: 'post',
        data: params,
    });
}
//设备转换模块--读取基础设备信息
export function getBasicEquipmentList(params) {
    return request({
        url: "bivale/entrance/equipment/base/list",
        method: 'post',
        data: params,
    });
}
//设备转换模块--保存转换信息
export function saveEquipmentConvert(params) {
    return request({
        url: "bivale/entrance/equipment/convert/save",
        method: 'post',
        data: params,
    });
}
//设备转换模块--取消設備轉換
export function removeEquipmentConvert(params) {
    return request({
        url: "bivale/entrance/equipment/convert/remove",
        method: 'post',
        data: params,
    });
}

// 门禁模块--车牌管理--车牌信息编辑保存
export function saveCarPlateNumber(param) {
    return request({
        url: "bivale/authentication/carPark/car/info/save",
        method: 'post',
        data: param
    });
}


// 门禁模块--车牌管理--车牌信息删除
export function deleteCarPlateNumber(param) {
    return request({
        url: "bivale/authentication/carPark/car/info/delete",
        method: 'post',
        data: param
    });
}

// 门禁模块 -- 卡片记录数据获取（分页） bivale/history/single/operation
export function getPageList(params) {
    return request({
        url: "bivale/history/page/list",
        method: 'post',
        data: params,
    });
}

// 门禁模块 -- 单条记录的操作 
export function recordOperation(params) {
    return request({
        url: "bivale/history/single/operation",
        method: 'post',
        data: params,
    });
}

// 门禁模块 -- 门禁详情 -- 通行权限 -- 获取绑定通行模式列表
export function getBindingTrafficMode(params) {
    return request({
        url: "bivale/entrance/authen/accessRole/list",
        method: 'post',
        data: params,
    });
}

// 门禁模块 -- 门禁详情 -- 通行权限 -- 获取绑定通行模式人员数据（分页）
export function getAccessRoleData(params) {
    return request({
        url: "bivale/accessRole/authen/page/persons",
        method: 'post',
        data: params
    });
}

// 门禁模块 -- 门禁详情 -- 通行权限 -- 导出绑定通行模式人员数据（当前页和全部）
export function exportAccessRoleData(params,type) {
    return fileDownload({
        url: `bivale/accessRole/authen/person/download/${type}`,
        method: 'post',
        responseType: "blob",
        data: params
    });
}

// 门禁模块 -- 门禁详情 -- 通行人员 -- 获取通行人员绑定数据（分页）
export function getBindPeople(params) {
    return request({
        url: "bivale/entrance/authen/page/persons",
        method: 'post',
        data: params,
    });
}

// 门禁模块 -- 门禁详情 -- 通行人员 -- 导出通行人员绑定数据（当前页和全部）
export function exportBindPeople(params,type) {
    return fileDownload({
        url: `bivale/entrance/authen/person/download/${type}`,
        method: 'post',
        responseType: "blob",
        data: params
    });
}

// 门禁模块 -- 设备页 -- 获取门禁终端列表
export function getEquipmentList(params) {
    return request({
        url: 'bivale/entrance/equipment/door/list',
        method: 'get',
        params
    });
}

// 门禁模块 -- 设备页 -- 详情页 -- 获取门操作状态
export function getDoorOperationStatus(params) {
    return request({
        url: 'bivale/entrance/equipment/door/itemStatus',
        method: 'get',
        params
    });
}

export function getHistoryTotalData(data) {
    let url = 'bivale/history/total';
    return request({
        url: url,
        method: 'post',
        data:data
    });
}

export function getHistoryStatisticsData(data) {
    let url = 'bivale/history/statistics';
    return request({
        url: url,
        method: 'post',
        data:data
    });
}